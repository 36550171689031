.container {
    width: 315px;
    margin-bottom: 35px;
    border: 1px solid var(--primary);
    border-radius: 20px;
    padding: 20px;
}

.container__header {
    display: flex;
    margin-bottom: 20px;
}

.container__header_photo {
    min-width: 120px;
    min-height: 110px;
    margin-right: 10px;
}

.container__header_photo > * {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 15px;
}

.container__header_info__username {
    font-family: 'BoldGilroy';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.container__header_info__specializations {    
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.container__date {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.container__date::first-letter{
    text-transform: uppercase;
}

.container__times {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.container__times > div {
    box-sizing: border-box;
    text-align: center;
    background-color: color-mix(in lch, var(--doctor_time), transparent 59%);
    border-radius: 5px;
    padding: 10px;
    width: 69px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: var(--primary);
}

.container__times > div:hover{
    background-color: var(--doctor_time);
}


@media (max-width: 720px) {
    .container {
        width: 95%;
        margin-left: auto;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .container__header {
        margin-bottom: 5px;
    }
    

    .container__header_photo > * {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 15px;
    }

    .container__header_info__username {
        font-family: 'BoldGilroy';
        font-size: 22px;
    }

    /* .container__times {
        justify-content: center;
    } */
    
    .container__times > div {
        width: 60px;
        padding: 7px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: black;
    }
}