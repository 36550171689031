
.tab {
    padding: 5px;
    border-bottom: 3px solid var(--gray);
    color: var(--gray);
    width: 100%;
    cursor: pointer;
    font-family: 'Apercu Pro';
    font-weight: 500;
}

.tab__container {
    display: flex;
    align-items: center;
}

.tab.accessible {
    color: var(--primary);
    border-bottom: 3px solid var(--primary);    
}

.tab.active {
    color: black;
    border-bottom: 3px solid var(--primary);
}

.tab__number {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;    
    background-color: var(--gray);
    border-radius: 50%;
    color: white;
}

.tab__number.active {
    background-color: var(--primary);
    
}

.tab__number.accessible {
    background-color: var(--primary);
    font-weight: 600;
}

.tab__title {
    width: 100%;
    text-align: center;
}