.doctors_item {
    box-sizing: border-box;
    width: 100%;
    border: 2px solid var(--secondary);
    background-color: var(--secondary);
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
}

.doctors_item:hover {
    border: 2px solid var(--primary);
}