.services {   
    margin-top: 40px;
}

.services__text {
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: 600;
    color: var(--primary);
}

.service {
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}

.service:hover {
    background-color: #D9D9D9;
}

@media (max-width: 720px) {
    .services__text {
        font-size: 18px;
        font-weight: 600;
    }    
}