@font-face {
    font-family: 'Apercu Pro';
    src: local('Apercu Pro Medium'), local('ApercuPro-Medium'),
        url('ApercuPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: local('Apercu Pro Regular'), local('ApercuPro-Regular'),
        url('ApercuPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}