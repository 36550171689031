.modal {
    position: fixed;
    top: 100px;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    overflow: auto;
    background-color: white;
}

.modal__container {
    width: 100%;
    height: fit-content;
    max-width: 1000px;
    padding-left: 25px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: white;    
    font-weight: 600;
}

.modal__container_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
}

.modal__close_btn {
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.modal__header {
    display: flex;
}

.modal__image {
    width: 200px;
    border-radius: 10px;
    margin: 0 40px  0 20px;
}

.modal__name {
    font-weight: 600;
    font-size: larger;
    margin-bottom: 10px;
}

.modal__specializations {
    font-weight: 600;
    margin-bottom: 10px;
}

.modal_doctor__service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 98%;
}

.modal_doctor__service_name {
    width: 60%;
}

.modal_doctor__service_price {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 5px;
}

.modal_doctor__service_price_text {
    margin-right: 10px;
    font-weight: bolder;
}

@media (max-width: 720px) {
    .modal__container {
        padding-right: 10px;
    }
    .modal__container_title {        
        font-family: 'BoldGilroy';
    }

    .modal__image {
        width: 120px;
        margin: 0;
        margin-right: 10px;
    }

    .modal__name {
        font-family: 'BoldGilroy';       
    }
    
    .modal__specializations {
        font-family: 'BoldGilroy';        
    }

    .modal_doctor__service {
        font-family: 'BoldGilroy';
    }
    
    .modal_doctor__service_name {
        font-family: 'BoldGilroy';
    }
    .appointment_button {
        font-family: 'Gilroy';
        font-weight: 600;
        font-size: 10px;
    }
}