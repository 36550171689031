.react-datepicker {
    font-family: "Gilroy";
    font-size: 14px;
    border-color: var(--primary);
    border-radius: 0px;
    margin-left: 18px;
}

.react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    border-color: var(--primary);
}

.react-datepicker__header {
    background-color: white;
    border-bottom: 0;
    padding-bottom: 0;
}

.react-datepicker__current-month {
    margin-bottom: 15px;
}

.react-datepicker__current-month::first-letter {
    text-transform: uppercase;
}

.react-datepicker__month {
    font-weight: bolder;
    padding: 8px;
    padding-top: 0;
}

.react-datepicker__day {
    border-radius: 10px;
    color: var(--primary);
}

.react-datepicker__day-names>div::first-letter {
    text-transform: uppercase;
}


.react-datepicker__day--selected {
    color: white;
    background-color: var(--primary);
}

.react-datepicker__day--selected:hover {
    border-radius: 10px;
    background-color: var(--secondary);
}

.react-datepicker__day--disabled {
    color: #D9D9D9;
}

.react-datepicker__triangle {
    display: none;
}


.custom-date-input__container {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid var(--secondary);
    border-radius: 10px;
    width: 219px;
    height: 33px;
    padding-left: 15px;
}

.custom-date-input__container:hover {
    border: 1px solid var(--primary);
}

.custom-date-input__container input{
    text-align: center;
    border: none;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;
    font-family: "Gilroy";
}

.react-datepicker-popper {
    left: 15px;
}