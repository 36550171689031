
.container {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px;
    background-color: var(--secondary);
    border-radius: 10px;
    cursor: pointer;
    color: var(--primary);
}



.container:hover{
    background-color: var(--primary);
    color: white;
}

.container.active{
    background-color: var(--primary);
    color: white;
}

.container.active:hover,
.container.active:hover > *{
    background-color: var(--secondary);
    color: var(--primary);
}

.radius15 {
    border-radius: 15px;
}

.radius20 {
    border-radius: 20px;
}

.disabled, 
.disabled.active, 
.disabled.active:hover, 
.disabled.container.active:hover > *,
.disabled:hover {
    background-color: color-mix(in lch, var(--secondary), white);
    color: var(--secondary);
    cursor: default;
}


@media (max-width: 720px) {
    .container.active:hover,
    .container.active:hover > *{
        background-color: var(--primary);
        color: white;
    }
}