@font-face {
  font-family: "Gilroy";
  src: url("../src/fonts/Gilroy/Gilroy-Light.otf");
}

@font-face {
  font-family: "BoldGilroy";
  src: url("../src/fonts/Gilroy/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "BoldGilroy";
  src: url("../src/fonts/Gilroy/Gilroy-Bold.ttf");
}

.App {
  padding: 5px;
  max-width: 1000px;
  margin: auto;
  padding-bottom: 50px;
  font-family: "Gilroy";
}