.specializations {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}

@media (max-width: 720px) {
    .specializations {
        width: 100%;
        justify-content: center;
    }
}