.types {
    margin-top: 60px;
}

.types__container {
    display: flex;
    margin-bottom: 5px;
}

.types__container > div {
    margin-right: 10px;
}

.types__title {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 600;
}

@media (max-width: 720px) {
    .types {
        margin-top: 20px;
    }
    .types__container {
        justify-content: center;
        padding-left: 10px;
    }

    .types__title {
        text-align: center;
        font-size: 16px;

        margin-bottom: 10px;
    }
}