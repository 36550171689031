.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4444;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    background-color: white;
    border: 3px solid var(--primary);
    border-radius: 20px;
    padding: 30px;
}

@media (max-width: 600px) {
    .modal__container {
        width: auto;
        margin: 10px;
        padding: 15px;
    }
}






.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.rating > input {
    display: none;
}

.rating > label {
    position: relative;
    width: 1.1em;
    font-size: 60px;
    color: #FFD234;
    cursor: pointer;
}

.rating > label::before {
    content: "\2605";
    position: absolute;
    opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
    opacity: 1 !important;
}

.rating > input:checked ~ label:before {
    opacity: 1;
}

.rating:hover > input:checked ~ label:before {
    opacity: 0.4;
}


.textarea__container {
    border: 2px solid var(--primary);
    border-radius: 15px;
    width: 100%;
}

.textarea__container textarea {
    width: 80%;
    margin: 10px;
    -webkit-appearance: none;
    appearance: none;   
    font-weight: 500;     
    overflow: hidden;
    border: none;
    resize: none;
}

.text1 {
    font-family: 'Apercu Pro';
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
}

.text2 {
    font-family: 'Apercu Pro';
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 18px;
}

.text3 {
    font-family: 'Apercu Pro';
    color: #8C8C90;
    margin-top: 5px;
    margin-bottom: 10px;
}

.button_container {
    display: flex;
    justify-content: right;
    width: 100%;
}

.button {
    font-family: 'Apercu Pro';
    font-weight: 500;
    font-size: 20px;
}

