.branch__container {
    box-sizing: border-box;
    width: 200px;
    min-height: 114px;
    border: 1px solid var(--primary);
    border-radius: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    font-family: 'Apercu Pro';
    font-weight: normal;
    margin-bottom: 16px;
}

.branch__name {
    margin-left: 8px;
}

.branch__address {
    margin-left: 8px;
}

.branch__container.active{
    background-color: var(--primary);
    color: white;
}

.branch__container:hover {
    background-color: var(--secondary);
}


@media (max-width: 720px) {
    .branch__container {
        box-sizing: border-box;
        width: 156px;
        min-height: 106px;
        font-size: 14px;
        padding: 12px;

    }

    
    .branch__container.active{
        border: 3px solid var(--primary);
    }
}
