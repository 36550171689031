.specialization {    
    background-color: var(--secondary);
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 22px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 600;
}

@media (max-width: 720px) {
    .specialization {
        margin-bottom: 10px;
        margin-right: 5px;
    }
}