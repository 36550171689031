.header {
    width: 100%;
    height: auto;
    margin-top: 10px;
    padding-bottom: 30px;
}

.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    display: flex;
    width: 50%;
    margin-right: 10px;
}

.header__logo a img {
    height: 70px;
    width: 100%;
    object-fit: contain;
}

.header__right {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.header__right-info {
    text-align: right;
    margin-right: 10px;
}

.header__right-info div:last-child {
    margin-top: 8px;
}

.header__right-info__phone {
    font-size: 24px;
    font-weight: 600;
    color: black;
    margin-right: 5px;
}

a.text:active, /* активная/посещенная ссылка */
a.text:hover,  /* при наведении */
a.text {
  text-decoration: none;
  color: black;
}

.header__button_text{
    font-weight: 600;
    color: var(--secondary);
    margin-left: 5px;
    margin-right: 10px;
    height: 29px;
    align-content: center;
}