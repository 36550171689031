.container {
    display: flex;
}

.parameters {
    width: 215px;
    margin-right: 50px;
    margin-bottom: 20px;
}

.title {
    display: flex;
    font-size: 28px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 25px;    
}

.title div {
    width: 313px;
}

.title div:last-child {
    width: auto;
}


.select_date {
    margin-bottom: 20px;
}

.select_text {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
}

.title_doctors {
    width: 100%;
}

.dayParts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    font-size: 16px;
    width: 215px;
}

.loader_container {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1.5s show infinite;
}

.loader {
    width: fit-content;
    margin: auto;
}

@keyframes show {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.8);
        opacity: 0.6;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.doctors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 720px) {
    .container {
        flex-direction: column;
    }

    .title {
        display: flex;
        justify-content: left;
        font-size: 21px;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 15px;
    }

    .title_mobile {
        width: auto;
        margin-left: 16px;
        margin-right: 16px;
        text-align: center;
    }

    .parameters {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        padding-left: 10px;

    }

    .select_text {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .select_date {
        margin-bottom: 5px;
        
    }

    .dayParts {
        margin-bottom: 15px;
    }

    .title_doctors {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-left: 10px;
    }
}