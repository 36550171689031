.tabs__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.btn_back{
    width: fit-content;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--secondary);
    cursor: pointer;
    margin-top: 15px;
}


.btn_back:hover {
    color: var(--primary);
}

.btn_back:hover > .arrow {
    color: var(--secondary);
    background-color: var(--primary);
}

.arrow {
    display: inline-block;
    text-align: center;
    align-content: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace;
    width: 22px;
    height: 22px;    
    background-color: var(--secondary);
    color: #EDEDED90;
    border-radius: 50%;
}