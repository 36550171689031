.container {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 17px 34px;
    background-color: var(--secondary);
    border-radius: 20px;
    cursor: pointer;
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
}

.container.active{
    background-color: var(--primary);
    color: white;
}



@media (max-width: 720px) {
    .container {
        padding: 10px 15px;
        background-color: var(--secondary);
        border-radius: 15px;
    }
}