.doctorAndService {
    margin-top: 60px;
    width: inherit;
}

.doctorAndService__title {
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 600;
}

.accessor{
    position: absolute;   
    width: 99%; 
    max-width: 1000px;
    max-width: 1000px;
    height: 50%;
    overflow: hidden;
    background-color: #FFFFFF99;
}

@media (max-width: 720px) {

    .doctorAndService {
        padding: 0 10px;
        margin-top: 20px;
    }

    .doctorAndService__title {
        text-align: center;
        font-size: 16px;
    }

    .doctorAndService__title {
        margin-bottom: 10px;
        
    }
}