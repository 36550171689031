.doctors {
    margin-top: 40px;
}

.doctors__text {
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: 600;
    color: var(--primary);
}

.doctors__container {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    width: 100%;
}


@media (max-width: 720px) {
    .doctors__text {
        font-weight: 600;
        font-size: 18px;
    }    

    .doctors__container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}
