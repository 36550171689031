.types {
    margin-top: 30px;
}

.types__container {
    display: flex;
    padding-top: 30px;
}

.types__container > div {
    margin-right: 15px;
}

.types__title {
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 720px) {

    .types {
        margin-top: 5px;
    }

    .types__container {
        justify-content: center;
        padding-top: 10px;
    }

    .types__title {
        text-align: center;
        font-size: 16px;
    }
}