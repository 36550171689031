.search__container {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 15px;
    height: 40px;
    margin-bottom: 30px;
}

.search__container_icon {
    margin: 0 13px;
}

.search__container input {
    width: 80%;
    border: none;
    -webkit-appearance: none;
    appearance: none;   
    font-weight: 500; 
}
.search__icon {
    position: absolute;
    background-color: var(--primary);
    width: 70px;
    height: 100%;
    right: 0;
    border-radius: 0px 12px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}  

@media (max-width: 720px) {
    .search__container {        
        margin-bottom: 20px;
    }
}