.branches {
    margin-top: 60px;    
}

.branches__container {
    display: flex;
    flex-wrap: wrap;
}

.branches__container > div {
    margin-right: 15px;
}

.branch__title {
    font-size: 16px;
    margin-bottom: 25px;
    font-weight: 600;
}

@media (max-width: 720px) {

    .branches {
        margin-top: 20px;    
    }

    .branches__container {
        justify-content: center;
        padding-left: 10px;
    }

    .branch__title {
        text-align: center;
        font-size: 16px;
        margin-bottom: 10px;
    }
}