.container {
    width: 405px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.title{
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 40px;
}

.inputs {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin-bottom: 30px;
}

.inputs input {
    font-family: "Gilroy";
    font-weight: 600;
    height: 34px;
    width: 80%;
    margin: 15px 0;
    padding-left: 20px;
    border: 1px solid var(--primary);
    border-radius: 17px;
}

.inputs input::placeholder {
    font-weight: 400;
}

/* .custom-date-input__container {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 17px;
    width: 300px;
    height: 42px;
    padding-left: 15px;
    margin: 15px 0;
} */

.inputs input:hover {
    border: 1px solid var(--secondary);    
}

.inputs input:focus {
    box-shadow: 0px 0px 8px var(--secondary);
}

/* .custom-date-input__container:hover {
    border: 1px solid var(--secondary);
}

.custom-date-input__container input:focus {
    box-shadow: none;
}

.custom-date-input__container input{
    text-align: center;
    border: none;
    margin-left: auto;
    margin-right: auto;
    height: 32px;
}

.custom-date-input__container input:hover{
    border: none;
} */

.agreements {
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 16px;
}

.button_container {
    display: flex;
    justify-content: center;    
}

.button {
    font-size: 24px;
    font-weight: 600;
    margin: 5px 15px;
}

.inputs .required {
    height: 0;
}

.inputs .required::after {
    content: "*";
    color: red; 
    position: relative;
    text-align: end;
    top: 25px;
    left: 8px;
    font-size: 18px;
}

.finish_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.finish_info div{
    font-size: 18px;
    margin-bottom: 10px;
}

.info{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 15px;
    font-weight: 600;
}

.upper {
    display: inline-block;
}

.upper::first-letter {
    text-transform: uppercase;
}


@media (max-width: 720px) {

    .container {
        width: fit-content;
        margin: 0;
        padding-right: 16px;
        padding-left: 16px;
    }

    .title{
        font-size: 22px;
        margin-top: 20px;
        margin-bottom: 14px;
    }

    .inputs {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
    }
    
    .inputs input {
        box-sizing: border-box;
        height: 30px;
        width: 100%;
        margin: 0;
        margin-bottom: 11px;    
    }

    .inputs .required::after {
        top: 7px;
        left: 10px;
        font-size: 18px;
    }

    .custom-date-input__container {
        width: 100%;
        height: 30px;
        padding-left: 15px;
        padding-right: 35px;
        margin: 0;
        overflow: hidden;
    }
    
    .custom-date-input__container input{
        margin-top: 10px;
    }    

    .info {
        margin-bottom: 15px;
    }

    .agreements {
        text-align: center;
    }
}

.finish_title {
    font-size: 32px;
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 20px;
}

.finish_info {
    margin-top: 20px;
}

.finish_footer {
    color: var(--gray);
    margin-top: 20px;
    font-size: 14px;
}

.doctor_container {
    width: 400px;
    border: 4px solid var(--doctor_time);
    border-radius: 20px;
    padding: 20px;
    font-size: 21px;
    font-weight: 600;
}

.doctor_container__header {
    display: flex;
    margin: 20px 0;
}

.doctor_container__header_photo {
    min-width: 120px;
    min-height: 110px;
    margin-right: 10px;
}

.doctor_container__header_photo > * {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 15px;
}

.doctor_container__header_info__username {
    font-family: 'BoldGilroy';
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.doctor_container__header_info__specializations {    
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
}

.doctor_container__date {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.doctor_container__date::first-letter{
    text-transform: uppercase;
}

@media (max-width: 720px) {

    .finish_title {
        font-size: 22px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 11px;
    }


    .doctor_container {
        box-sizing: border-box;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
    }

    .doctor_container__header {
        margin: 10px 0;
    }
    
    .doctor_container__header_photo {
        min-width: 90px;
        min-height: 90px;
        margin-right: 10px;
    }

    .doctor_container__header_photo > * {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 15px;
    }

    .doctor_container__header_info__username {
        font-family: 'BoldGilroy';
        font-size: 22px;
    }

    .doctor_container__header_info__specializations {    
        margin-top: 0px;
    }

    .finish_info {
        margin-top: 5px;
    }

    .finish_footer {
        color: var(--gray);
        margin-top: 10px;
        font-size: 14px;
    }
}