.doctorSearch__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 274px;
    margin-bottom: 35px;
    border: 1px solid var(--primary);
    border-radius: 20px;
    padding: 20px 15px;
    overflow: hidden;
}

.button {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: end;
    font-weight: 600;
}

.button > * {
    width: 100%;
    text-align: center;
}

.doctorSearch__container:hover {
    background-color: #D9D9D9;
}

.doctorSearch__container_info {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.doctorSearch__photo {
    max-width: 70px;
    min-width: 70px;
    width: 70px;
    height: 70px;   
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;    
}

.doctorSearch__photo > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.doctor__specializations {
    color: var(--primary);
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 1.2em;
}

.doctorSearch__container_notime { 
    margin-bottom: 5px;
}

.doctorSearch__container_button {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

@media (max-width: 720px) {
    .doctorSearch__container {      
        width: auto;  
        border-radius: 10px;
        margin-bottom: 5px;
    }

    .doctorSearch__photo {
        max-width: 70px;
        min-width: 70px;
        width: 70px;
        height: 70px;        
    }

    .doctor__specializations {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .doctorSearch__container_button {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }
}


.container__times {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.container__times > div {
    box-sizing: border-box;
    text-align: center;
    background-color: var(--secondary);
    color: var(--primary);
    border-radius: 5px;
    padding: 10px 0;
    height: 38px;
    width: 30%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-wrap: nowrap;
}

.container__times > div:hover{
    background-color: var(--primary);
    color: var(--secondary);
}

.container__times > .skeleton {
    background-color: #dddddd;
    padding: 0;
    overflow: hidden;
    pointer-events: none;
}

.skeleton > div {
    width: 10px;
    height: 38px;
    position: relative;
    transform: rotate(20deg);
    animation: 2s animate linear infinite;
    background-color: #ffffffc6;
    box-shadow: 0px 0px 15px 15px #ffffffc6;
}

@keyframes animate {
    0%
    {
        left: -45%;
    }
    100%
    {
        left: 120%;
    }
}
